import React, { FC, useEffect, useState } from 'react';
import { MenuItem, Select, Typography } from "@material-ui/core";
import { Box, Input } from '@saleor/macaw-ui-next';
import { Button, makeStyles } from '@saleor/macaw-ui';
import { useMutation } from '@apollo/client';
import { customerDiscount } from '@dashboard/customers/mutations';
import useNotifier from '@dashboard/hooks/useNotifier';

const useStyles = makeStyles(
  {
    input: {
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    box: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      maxHeight: "72px",
    },
    flex: {
      display: 'flex',
      gap: '8px',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    button: {
      height: '40px',
    }
  },
  { name: "CustomerDiscount" },
);

type CustomerDiscountProps = {
  customerId: string;
  customerDiscountType: string;
  customerAmount: number;
}

const discountType = ["CHF", "%"]

export const CustomerDiscount: FC<CustomerDiscountProps> = ({ customerId, customerDiscountType, customerAmount }) => {
  const [selectedDiscountType, setSelectedDiscountType] = useState("CHF");
  const [discountAmount, setDiscountAmount] = useState('')

  const [discountMutation] = useMutation(customerDiscount);

  const classes = useStyles();
  const notify = useNotifier();

  useEffect(() => {
    if(customerDiscountType) {
      const type = customerDiscountType === "FIXED" ? "CHF" : "%";
      setSelectedDiscountType(type);
    }

    if(customerAmount) {
      setDiscountAmount(customerAmount.toString())
    } 

  }, [customerAmount, customerDiscountType])

  const onChangeType = (value: string) => {
    setDiscountAmount('');
    setSelectedDiscountType(value)
  }

  const applyDiscount = async () => {
    try {
      const type = selectedDiscountType === 'CHF' ? "FIXED" : "PERCENTAGE"
      const { data } = await discountMutation({
        variables: {
          id: customerId,
          amount: discountAmount,
          discountType: type,
        }
      })

      if(data?.customerUpdate?.errors?.length === 0) {
        notify({
          status: "success",
          title: 'Discount applied successfully.',
        })
      }
      if(data?.customerUpdate?.errors?.length !== 0) {
        notify({
          status: "error",
          title: 'Sorry, something went wrong.',
        })
      }
    } catch (e) {
      console.error(e);
    }
  }


  return (
    <Box className={classes.column} padding={6}>
      <Typography variant='h4'>
        Customer discount
      </Typography>
      <Box className={classes.box}>
        <Box className={classes.flex}>
          <Input
            label='Amount'
            size="small"
            value={discountAmount}
            onChange={e => setDiscountAmount(e.target.value)}
            name="transportCost"
            type="number"
            className={classes.input}
            height={4}
          />
          <Select
            value={selectedDiscountType}
            onChange={event => onChangeType(event.target.value as string)}
          >
            {discountType.map(item => {
              return (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              )
            })}
          </Select>
        </Box>
        <Button className={classes.button} onClick={() => applyDiscount()}>
          Apply discount
        </Button>
      </Box>
    </Box>
    
  )
}