import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Button, ResponsiveTable } from "@saleor/macaw-ui";
import { Box } from "@saleor/macaw-ui-next";
import React, { useState } from "react";

import {DeleteProductType, EditProductType, SupplyTransactionLineType} from "../types";
import { DeleteProductDialog } from "./DeleteProductDialog";
import { EditProductDialog } from "./EditProductDialog";
import { ProductLine } from "./ProductLine";

interface ProductTableProps {
  lines: SupplyTransactionLineType[];
  onAddProduct: () => void;
  onRefetch: () => void;
  isImported: boolean;
}

export const ProductTable: React.FC<ProductTableProps> = ({
  lines,
  onAddProduct,
  onRefetch,
  isImported,
}) => {
  const [lineData, setLineData] = useState<EditProductType>({
    id: "",
    quantity: 0,
    unitPrice: 0,
  });
  const [deleteLineData, setDeleteLineData] = useState<DeleteProductType>({
    id: "",
    name: ""
  });
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const resetLineData = () => {
    setLineData({ id: "", quantity: 0, unitPrice: 0 });
  };

  return (
    <div>
      <ResponsiveTable>
        <TableHead>
          <TableRow>
            <TableCell width={"25%"}>Product</TableCell>
            <TableCell width={"15%"}>Variant</TableCell>
            <TableCell width={"5%"}>Quantity</TableCell>
            <TableCell width={"15%"}>Single Price</TableCell>
            <TableCell width={"15%"}>Total</TableCell>
            <TableCell align="right">
              <Button
                onClick={onAddProduct}
                type="button"
                variant="primary"
                style={{ width: "max-content" }}
              >
                Add product
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lines?.length === 0 ? (
            <Box paddingLeft={8} paddingTop={4}>
              <Typography variant="body2">There are no products</Typography>
            </Box>
          ) : (
            <>
              {lines?.map(line => {
                return (
                  <ProductLine
                    key={line.id}
                    line={line}
                    price={line?.productVariant?.pricing}
                    onDelete={lineData => {
                      setDeleteLineData(lineData);
                      setOpenDeleteModal(true);
                    }}
                    onEdit={lineData => {
                      setLineData(lineData);
                      setOpenEditModal(true);
                    }}
                    isImported={isImported}
                  />
                );
              })}
            </>
          )}
        </TableBody>
      </ResponsiveTable>
      <EditProductDialog
        key={lineData.id}
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
          resetLineData();
        }}
        lineData={lineData}
        onSuccess={onRefetch}
      />
      <DeleteProductDialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        lineData={deleteLineData}
        onSuccess={onRefetch}
      />
    </div>
  );
};
