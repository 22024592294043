import { useQuery } from "@apollo/client";
import useAppChannel from "@dashboard/components/AppLayout/AppChannelContext";
import { productSupplierAndManufacturer } from "@dashboard/products/queries";
import { Typography } from "@material-ui/core";
import { Box } from "@saleor/macaw-ui-next";
import React, { FC } from "react";

import { ManufacturerDropdown } from "./ManufacturerDropdown";
import { SuppliersDropdown } from "./SuppliersDropdown";

interface SupplierManufacturerProps {
  productId: string;
}

export interface parsedSuppliersType {
  label: string;
  value: string;
}

export const SupplierManufacturer: FC<SupplierManufacturerProps> = ({
  productId,
}) => {
  const { channel } = useAppChannel(false);

  const { data } = useQuery(productSupplierAndManufacturer, {
    variables: {
      id: productId,
      channel: channel.slug,
    },
  });

  return (
    <Box
      paddingX={6}
      paddingTop={10}
      display="flex"
      flexDirection="column"
      gap={6}
    >
      {!data?.product && (
        <Typography variant="body2">
          Product needs to have channel selected in order to select Supplier and
          Manufacturer
        </Typography>
      )}
      <SuppliersDropdown productId={productId} />
      <ManufacturerDropdown productId={productId} />
    </Box>
  );
};
